html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure,
// footer, header, hgroup, menu, nav, section {
//   display: block;
// }
body {
  line-height: 1;
}
// blockquote, q {
//   quotes: none;
// }
// blockquote:before, blockquote:after,
// q:before, q:after {
//   content: '';
//   content: none;
// }
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

p[data-quid='Body-safelite-content-SubTitle2'] {
  background-color: #4470bf;
  padding: 2px 10px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  position: absolute;
  top: 1px;
  left: 330px;
}

div[data-quid='BladeContent-safelite-content'] {
  position: relative;
}
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  h2[id*='Subheadline'] {
    // confirm with UX
  }
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  height: 100vh;
}

.spinnerCancelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.copy-line {
  width: 100%;
  height: 4px;
  background-color: #09216a;
  margin-top: 16px;
}

.copy-footer {
  min-height: 75px;
  font-family: 'RobotoRegular', 'Segoe UI', 'HelveticaNeueLight', sans-serif;
}

.copy-title {
  margin: 40px 16px 10px;
  font-size: 16px;
  line-height: 24px;
  color: #2a282c;
}

.header-backlink {
  margin: 0 10px;
}

.username-backlink {
  margin: 0 0 10px 0;
}

.copy-title a {
  color: #09216a;
}

.copy-message {
  margin: 10px 16px 28px;
  padding-top: 20px;
  font-size: 16px;
  line-height: 24px;
  color: #2a282c;
}

@media screen and (min-device-width: 568px) {
  .copy-title {
    margin: 40px 24px 10px;
  }
  .copy-message {
    margin: 10px 24px 28px;
    padding-top: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .copy-title {
    margin: 40px 80px 10px;
  }
  .copy-message {
    margin: 10px 80px 28px;
    padding-top: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .copy-title {
    margin: 40px 80px 10px;
  }
  .copy-message {
    margin: 10px 80px 28px;
    padding-top: 20px;
  }
}

@media screen and (max-width: 500px) {
  p[data-quid='Body-safelite-content-SubTitle2'] {
    font-size: 14px;
    top: 33px;
    left: 130px;
  }
}
